
//import Rails from 'rails-ujs'
//Rails.start()

import { Controller } from "stimulus";
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
        animation: 150,
        filter: 'input, button,',
        onEnd: this.end.bind(this)
    })
    //console.log($("#sort-me"));

  }  
  end(event) {
    var sortable = this.sortable;
    let combo_id = event.item.dataset.id
    let entry_id = combo_id.substr(0, combo_id.indexOf("_"));
    let id = combo_id.substr(combo_id.indexOf("_") + 1, combo_id.length);
    //let id = event.item.dataset.id
    //let entry_id = event.item.dataset.entryid
    
    let current_order = $('#image_order_array_' + entry_id).val();
    
    let current_position = $('#image_position_' + id).val();
    
    let data = new FormData()
    data.append("current_order", current_order)
    data.append("current_position", current_position)
    data.append("new_position", event.newIndex)
    data.append("old_position", event.oldIndex)
    
    document.getElementById('lock_job_entry_' + entry_id).setAttribute('stroke','red');
    document.getElementById('path_job_entry_' + entry_id).setAttribute('d','M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z');
    
    //var idsInOrder = this.sortable("toArray");

    const divIds = $.map($('#entry_' + entry_id + ' > div'), div => div.id);

    var new_order =[];
    var i;
    for (i = 0; i < divIds.length; ++i) {
        if(divIds[i].includes("attachment")) {
          var index = divIds[i].indexOf('_');
          var image_id = divIds[i].substr(index +1,10);
          new_order.push(image_id);
        }
    }   
      
    $('#image_order_array_' + entry_id).val(new_order);
      
    //Rails.ajax({
    //  url: this.data.get("url").replace(":id", id),
    //  type:'PATCH',
    //  data: data      
      
    //})
  }  

}