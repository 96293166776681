import { Controller } from "stimulus"


export default class extends Controller {
  
  static targets = [ "entryId", "output" ]  
  
  // Called when Stimulus create an instance of the
  // CounterController class.
  initialize(){
    //this.clickCount = 0
  }
  
  reorder(event) {
    
    //const entry_id = this.entryTarget.dataset.reorderEntryId
    const id = event.target.id;
    const entry_id = id.substr(15,7);
    //let combo_id = event.item.dataset.id
    var new_order = $('#image_order_array_' + entry_id).val();
    
    document.getElementById('lock_job_entry_' + entry_id).setAttribute('stroke','black');
    document.getElementById('path_job_entry_' + entry_id).setAttribute('d','M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z');

    let data = new FormData();
    data.append("new_order", new_order);
    
    let url = '/job_entries/' + entry_id + '/reorder'
    
    Rails.ajax({
      url: url,
      type:'PATCH',
      data: data 
    });
  }

};