

import { FileChecksum } from "@rails/activestorage/src/file_checksum";

const myCloudName   = "dbkudbb5k";
const widgetElement = "widgetdiv";
const submitButton  = document.getElementById("submission_button");
let assetCount      = 0

function launchUploadWidget() {
  cloudinary.openUploadWidget(
    {
      cloudName: myCloudName,
      inlineContainer: `#${widgetElement}`,
      form: "#entity_form",
      uploadPreset: "zuaep6pz",
      multiple: true,
      showPoweredBy: false,
      sources: ["local"],
      styles: {
        palette: {
          textDark: "#FFFFFF",
          action: "#FFFFFF",
        },
      },
      return_delete_token: true,
    },
    (error, result) => {
      if (!error && result) {
        submitButton.disabled = true;

        // Step 1: Upload the asset to the Media Library
        if (result.event === "queues-end") {
          submitButton.value = "Processing...";
          assetCount += result.info.files.length;

          for (const singleFile of result.info.files) {
            var cldTargetUrl = singleFile.uploadInfo.secure_url;
            var dpublicid = singleFile.uploadInfo.public_id;
            var dfilename = singleFile.name;
            var dfilesize = singleFile.size;
            var dcontentType = singleFile.type;

            cloudinaryUpload(
              cldTargetUrl,
              dpublicid,
              dfilename,
              dfilesize,
              dcontentType
            );
          }
        }
      }
    }
  );
}

function cloudinaryUpload(cldTargetUrl, dpublicid, dfilename, dfilesize, dcontentType) {
  var myUrl = "/api/v1/active_storage"

  fetch(cldTargetUrl, { mode: "cors" })
    .then((res) => res.blob())
    .then((blob) => {
      // Step 3: Get the image from the repository (i.e., Cloudinary)
      const file = new File([blob], dfilename, {
        type: dcontentType,
      });
      // Step 4: Calculate the parameters that is needed for the calculation of Blob.signed_id at the server side of the application
      FileChecksum.create(file, (error, checksum) => {
        if (error) {
          console.log(`Checksum error: ${error}`);
          return;
        }
        var adata = {
          filename: dfilename,
          byte_size: dfilesize,
          checksum: checksum,
          content_type: dcontentType,
          public_id: dpublicid,
        };
        // Step 5: Delegate to the server side the calculation of the Blob.signed_id together with the parameters to rename the asset in Media Library
        fetch(myUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(adata),
        })
          .then((response) => response.json())
          .then((data) => {
            // Step 6. Create/Update the information in the hidden input
            createHiddenInput(data.signed_id);

            // Step 7. Rename the asset in the Medial Library to synchronize it with the information stored in the ActiveStorage
            updateCldMLAsset(data);
          });
      });
    });
}

function updateCldMLAsset(cldData) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", cldData.baparams);

  var formdata = new FormData();
  formdata.append("from_public_id", cldData.cldfrompublicid);
  formdata.append("to_public_id", cldData.cldtopublicid);
  formdata.append("timestamp", cldData.cldtimestamp);
  formdata.append("api_key", cldData.cldapikey);
  formdata.append("signature", cldData.cldsignature);

  var requestOptions = {
    mode: "no-cors",
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  fetch(cldData.cldrenameurl, requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

function createHiddenInput(computedvalue) {
  const inputName = "entity[job_entries_attributes][0][images][]";
  const input = document.createElement("input");
  input.type = "hidden";
  input.name = inputName;
  input.value = computedvalue;

  const myElement = document.getElementById(widgetElement);
  myElement.appendChild(input);

  const hiddenElements = document.getElementsByName(inputName);

  if (hiddenElements.length === assetCount) {
    submitButton.value = "CREATE ENTRY!";
    
    submitButton.disabled = false;
  }
}

launchUploadWidget();
