// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
require("@rails/activestorage").start()

//import Rails from 'rails-ujs'
//Rails.start()

require("channels")

require("jquery")
require("jquery-ui-dist/jquery-ui");
require('jquery-ui/ui/widgets/sortable');

//import Sortable from 'sortablejs'

// Tailwindcss
import "stylesheets/application"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
require("custom/direct_uploads")
require("packs/activeStorage")

import "controllers"

// Add Turbo to handle Turbo Stream responses
import { Turbo } from "@hotwired/turbo-rails";
window.Turbo = Turbo;

//import { Application } from 'stimulus'
//import { autoload } from 'stimulus/webpack-helpers'

//const application = Application.start()
//const controllers = require.context("./controllers", true, /\.js$/)
//autoload(controllers, application)

